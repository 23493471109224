body {
  background-color: #F6F1E9!important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.orange-cl{
  color: rgb(255, 128, 0);
}
.brand-name{
  cursor: pointer;
  font-weight: 1000;
  text-shadow: 2px 2px #bcb9b9ac;
  transition: 1s;
  padding: 5px 7px;
  border-radius: 7px;
}
.brand-name:hover{
  background-color: #FFE6C7;
}
.cntctus{
  font-size: 20px;
  padding: 5px 10px;
  border-radius:7px;
  cursor: pointer;
  margin-left: 15px;
  transition: 0.5s!important;
}
.cntctus:hover{
  background-color:rgb(255, 128, 0)!important;
  color: aliceblue!important;
  /* letter-spacing: 0.4px; */
}
.hdn-logo{
  display: none;
}

.crsl-hgt{
  height: 500px;
  border-radius: 10px;
}
.lst-none{
  list-style: none;
}
.lnksft{
  /* margin-left:20px; */
  /* background-color: #FFE6C7; */
  color: black;
  text-decoration: none;
  /* border-radius: 50%; */
  cursor: pointer;
}

.logo-sec{
  margin-right: 0!important;
}
.crsr-pntr{
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  *{
    overflow-x: hidden;
}
.sdm-dsg{
  font-size: 10px!important;
}
}
@media screen and (min-width: 780px) {
  .card-img-top {
    height: 200px!important;
    width: unset!important;
}
}
@media screen and (max-width: 650px) {
  .logo-sec{
    width: 100px!important;
  }
  .brand-name{
    font-weight: 600;
    text-shadow: 2px 2px #bcb9b9ac;
    padding: 0;
    font-size: 20px;
  }
  .brand-name:hover{
    background-color: none;
  }
  .brand-name p{
    font-size: 15px!important;
  }
  .crsl-hgt{
    height: 350px;
  }
}
@media screen and (max-width: 450px) {
  .logo-sec{
    width: 70px!important;
  }
  .brand-name{
    font-weight: 600;
    text-shadow: 2px 2px #bcb9b9ac;
    padding: 0;
    font-size: 15px;
  }
  .brand-name:hover{
    background-color: none;
  }
  .brand-name p{
    font-size: 10px!important;
  }
  .cntctus{
    padding: 10px!important;
    margin-right: 15px;
  }
  .crsl-hgt{
    height: 300px;
  }
  .dp-sdm{
    width: 90px!important;
  }
  .sdm-dsg{
    font-size: 10px!important;
  }
  .kdi-dtls{
    font-size: 10px!important;
  }
  .cntct-logo{
    flex-direction: column!important;
    margin-top:10px!important;
  }
}
@media screen and (max-width: 420px) {
  .sdm-dsg{
    display: none!important;
  }
}
@media screen and (max-width: 350px) {
  .logo-sec{
    width: 50px!important;
  }
  /* .brand-name{
    font-weight: 600;
    text-shadow: 2px 2px #bcb9b9ac;
    padding: 0;
    font-size: 15px;
  }
  .brand-name:hover{
    background-color: none;
  }
  .brand-name p{
    font-size: 10px!important;
  } */
  .cntctus{
    padding: 10px!important;
    margin-right: 15px;
  }
  .crsl-hgt{
    height: 250px;
  }
}
@media screen and (max-width: 300px) {
  .logo-sec{
    display: none;
  }
  /* .brand-name{
    font-weight: 600;
    text-shadow: 2px 2px #bcb9b9ac;
    padding: 0;
    font-size: 15px;
  }
  .brand-name:hover{
    background-color: none;
  }
  .brand-name p{
    font-size: 10px!important;
  } */
  .cntctus{
    padding: 7px!important;
    align-items: center;
    justify-content: center;
  }
  .dp-sdm{
    width: 60px!important;
  }
  .sdm-dsg{
    display: none!important;
  }
  .kdi-dtls{
    display: none!important;
  }
  .cntct-logo{
    /* flex-direction: row!important; */
    padding: 0!important;
    font-size:10px!important;
    box-sizing: unset!important;
    float: right;
    /* margin-top:10px!important; */
  }
  .hdn-logo{
    display: flex!important;
    width: 75px;
  }
}